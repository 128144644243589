@import "../../styles/mainStyles.scss";

.container {
  padding: 4rem 6rem;
  @include xl {
    // padding: 1rem 5rem;
  }
  @include l {
    padding: 3rem 4rem;
  }
  @include m {
    padding: 3rem 2rem;
  }
  @include s {
    padding: 2rem;
    gap: 1rem;
  }
  @include xs {
    padding: 1rem 1.5rem 2.5rem;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  @include s {
    width: 100%;
  }
}

.title {
  @include bold-title;
  text-transform: uppercase;
}

.description {
  @include description;
  font-size: 2rem;
}