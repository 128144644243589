@import "../../styles/mainStyles.scss";

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  padding: 4rem 0;
  gap: 1.75rem;
  @include s {
    padding: 0;
    gap: 1rem;
  }
}

.title {
  @include title;
  @include s {
    font-size: 2.5rem;
  }
}

.description {
  @include description;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 3rem 0 2rem;
  overflow: hidden;
  width: 100%;
  @include s {
    padding: 1rem 0;
    // justify-content: flex-end;
    width: 100%;
  }
}

.sliderWrapper {
  padding: 1.5rem 0;
  @include l {
    padding: 1.25rem 0;
  }
  @include m {
    padding: 1rem 0;
  }
  @include s {
    padding: 0.5rem 0;
  }
}

.slider {
  border-radius: 20px;
  overflow: visible !important;
  @include s {
    overflow: hidden !important;
  }

  div[class^="swiper-wrapper"] {
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    @include m {
      gap: 0.25rem;
    }
    @include s {
      gap: 0.125rem;
    }
  }

  div[class^="swiper-button-prev"],
  div[class^="swiper-button-next"] {
    transition: 0.3s;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    box-shadow: inset 0 0 0 2px $light;
    border-radius: 40px;

    &::after {
      transition: 0.3s;
      color: $light;
      font-size: 1rem;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }

    &:hover {
      box-shadow: inset 0 0 0 3px $light;
      &::after {
        color: white;
      }
    }
  }

  div[class^="swiper-button-prev"] {
    left: 7%;
    @include l {
      left: 4%;
    }
    @include s {
      left: 2%;
    }

    &::after {
      content: url("../../assets/icons/left-arrow.svg");
    }
  }
  div[class^="swiper-button-next"] {
    right: 7%;
    @include l {
      right: 4%;
    }
    @include s {
      right: 2%;
    }
    &::after {
      content: url("../../assets/icons/right-arrow.svg");
    }
  }
}

.nextSlide,
.prevSlide {
  display: flex;
  transition: 0.5s;
}
.nextSlide {
  justify-content: flex-end;
}
.prevSlide {
  justify-content: flex-start;
}

.activeSlide {
  position: absolute;
  z-index: 100;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content !important;

  .slide {
    height: 29.125rem !important;
    @include xl {
      height: 27.5rem !important;
    }
    @include l {
      height: 25rem !important;
    }
    @include m {
      height: 23.75rem !important;
    }
    @include s {
      height: 27.9rem !important;
    }
  }
}

.swiperSlide {
  display: flex;
  align-items: center;
  margin: 0 !important;
  animation-duration: 0s !important;
  transition-duration: 0s !important;
}

.slide {
  width: fit-content !important;
  border-radius: 20px !important;
  height: 18rem !important;
  @include xl {
    height: 16.423rem !important;
  }
  @include l {
    height: 15.423rem !important;
  }
  @include m {
    height: 14.423rem !important;
  }
  @include s {
    height: 12.4rem !important;
  }
}
