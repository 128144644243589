@import '../../styles/mainStyles.scss';

.button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .75rem 2.5rem;
  gap: 10px;

  width: 100%;
  border-radius: 30px;
  background: transparent;

  border: 1px solid transparent;
  -webkit-mask:
  linear-gradient($dark 0 0) content-box,
  linear-gradient($dark 0 0);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 50px;
    padding: 1px;
    background: $gradient-marketing;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  @include SairaRegular;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;

  color: $light;
  cursor: pointer;
  &:hover {
    color: $light-fifty;
  }
}