@import './colors.scss';
@import './fonts.scss';
@import './breakpoints.scss';

@mixin title {
  @include SairaRegular;
  font-style: normal;
  color: $light;
  font-weight: 300;
  font-size: 5.25rem;
  line-height: 90%;

  @include xl {
    font-size: 5rem;
  }
  @include l {
    font-size: 4.25rem;
  }
  @include m {
    font-size: 3.125rem;
  }
  @include s {
    font-size: 3rem;
    line-height: 120%;
  }
  @include xs {
    font-size: 2.375rem;
  }
}

@mixin bold-title {
  @include SairaExtraBold;
  font-style: normal;
  color: $light;
  font-weight: 800;
  font-size: 6rem;
  line-height: 90%;

  @include xl {
    font-size: 5.25rem;
  }
  @include l {
    font-size: 4.5rem;
  }
  @include m {
    font-size: 3.5rem;
  }
  @include s {
    font-size: 3.25rem;
  }
  @include xs {
    font-size: 3rem;
  }
}

@mixin description {
  @include SairaRegular;
  color: $light;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;

  @include xl {
    font-size: 1.375rem;
  }
  @include l {
    font-size: 1.125rem;
  }
  @include m {
    font-size: 1rem;
  }
  @include s {
    font-size: .9rem;
  }
  @include xs {
    font-size: .875rem;
  }
}

.dark-background {
  background: $dark;
}