@import "./blocks.scss";
@import "./fonts.scss";
@import "./breakpoints.scss";
@import "./colors.scss";
@import "./animations.scss";

#player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 999;
}
